import {Link} from "react-router-dom";
import { useEffect } from "react";
import * as bootstrap from 'bootstrap';
window.Collapse = bootstrap.Collapse;




function Header() {

  // По принципу componentDidMount и componentDidUpdate:
  useEffect(() => {
		console.log('рендер')
    // Обновляем заголовок документа, используя API браузера
    const navLinks = document.querySelectorAll('.nav-item')
		const menuToggle = document.getElementById('navbarNav')
		const bsCollapse = new window.Collapse(menuToggle, {toggle:false})
		const navbarToggler = document.querySelector('.navbar-toggler')

		// navbarToggler.addEventListener('click', () => { bsCollapse.toggle() })

			navLinks.forEach((link) => {
				link.addEventListener('click', () => { bsCollapse.toggle() })
		})
  }, []);

  return (
		<header className="header">
		<div className="header__container _container">
				<nav className="navbar navbar-expand-lg">
						<div className="container-fluid">
								<Link className="navbar-brand" to='/'> <img className="logo-head" src="/static/img/logo-head.png" alt=""/> </Link>
								<ul className="navbar-brand-soc-mobile">
										<li className="nav-item">
												<a className="nav-link nav-link__icon" href="https://t.me/kskpeople" > <img src="/static/img/tg-white.svg" alt="" /></a>
										</li>
										<li className="nav-item">
												<a className="nav-link nav-link__icon" href="https://wa.me/+79309153808"><img src="/static/img/whatsupp-white.svg" alt="" /></a>
										</li>
										<li className="nav-item">
												<a className="nav-link nav-link__icon" href="mailto:info@kskpeople.com"><img src="/static/img/mail-white.svg" alt="" /></a>
										</li>

								</ul>

								<button className="navbar-toggler" type="button" data-bs-toggle="collapse"
												data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
												aria-label="Toggle navigation">
										<span className="navbar-toggler-icon"> </span>
								</button>
								<div className="collapse navbar-collapse" id="navbarNav">
										<ul className="navbar-nav ms-auto menu">
												<li className="nav-item">
														<Link className="nav-link" to='/about'> О нас</Link>
												</li>
												<li className="nav-item">
														<Link className="nav-link" to='/services'>Услуги</Link>
												</li>
												<li className="nav-item">
														<Link className="nav-link" to='/portfolio'>Портфолио</Link>
												</li>
												<li className="nav-item">
														<Link className="nav-link" to='/trust'>Нам доверяют</Link>
												</li>
												<li className="nav-item">
														<Link className="nav-link" to='/contacts'>Контакты</Link>
												</li>
										</ul>

				<ul className="navbar-nav ms-auto menu">
					<li className="nav-item">
														<a className="nav-link nav-link__contacts" href="tel:88001016901">8 (800) 101-69-01</a>
												</li>
												<li className="nav-item nav-item-soc">
														<a className="nav-link nav-link__icon" href="https://t.me/kskpeople" > <img src="/static/img/tg-white.svg" alt="" /></a>
												</li>
												<li className="nav-item nav-item-soc">
														<a className="nav-link nav-link__icon" href="https://wa.me/+79309153808"><img src="/static/img/whatsupp-white.svg" alt="" /></a>
												</li>
												<li className="nav-item nav-item-soc">
														<a className="nav-link nav-link__icon" href="mailto:info@kskpeople.com"><img src="/static/img/mail-white.svg" alt="" /></a>
												</li>
												
										</ul>
								</div>
						</div>
				</nav>
		</div>
</header>
  );
}


export default Header