import Form from "../components/form";


const Contacts = () => (
	<main className="page6">


		<div className="feedback-second">
			<div className="feedback-second__image _ibg">
				<img src="/static/img/IMG_1534.webp" alt="cover"/>
			</div>
			<Form/>
		</div>

	</main>

)
	
	export default Contacts;