import { Link } from "react-router-dom";

const ListPage = () => (
	<>
		
		11111111111111
		<Link to="/services"><button type="submit" className="btn btn-default mb-3 button2">Список услуг</button></Link>
		<Link to="/contacts"><button type="submit" className="btn btn-default mb-3 button1">Заказать</button></Link>


	</>

)
	
	export default ListPage;